/* Colors */
// Page
$pageBackground: black;
$primary: rgb(3, 36, 90);
$secondary: white;

//Text
$fontColor: white;
$textColor: white;
$fontSize1: 15px;
$fontSize2: 22px;
$fontSize3: 28px;
$fontSize4: 40px;

$secondTextColor: black;

$success: rgb(25, 145, 14);
$warning: rgba(255, 251, 33, 1);
$error: rgba(255, 64, 80, 1);

$linkColor: rgba(64, 255, 249, 1);
$labelColor: rgba(222, 222, 222, 1);
$primaryButton: rgba(0, 242, 255, 1);
$secondarymButton: white;
$deleteButton: red;
$disabledButton: gray;

/* Border */
$borderRadius: 10px;

/* Shadows */
$loginShadow: 0px 0px 12px rgba(0, 225, 255, 0.4);
$shadow: 0px 0px 10px rgba(0, 225, 255, 0.4);
$shadowReadonly: 0px 0px 2px rgba(0, 225, 255, 0.4);
$insetShadow: inset 0px 0px 10px rgba(0, 225, 255, 0.4);
$shadowFocused: 0px 0px 17px rgba(0, 225, 255, 0.4);
$primaryButtonShadow: 0px 0px 8px $success;

$slowTransition: 0.3s all ease-in-out;
$fastTransition: 0.15s all ease-in-out;

//Table
$tableHeadColor: rgba(0, 0, 0, 1);
$tableBodyColor: rgb(0, 51, 133);
$tableRowHover: rgba(255, 255, 255, 0.2);
$tableFilterColor: rgba(61, 61, 61, 0.836);

//Button
$btnBackground: rgba(255, 255, 255, 0.2);
$btnHover: rgba(255, 255, 255, 0.35);
